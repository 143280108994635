class ToggleBox {
    constructor(element) {
        const elem = element || {};
        const ds = elem.dataset || {};
        const togglesActive = ds.togglesActive || '';
        this._active = togglesActive.split(' ');
    }

    static withEnabledToggles() {
        return new ToggleBox({ dataset: { togglesActive: Array.from(arguments).join(' ') } });
    }

    toggleActive(toggleName) {
        return this._active.indexOf(toggleName) !== -1;
    }
}

export { ToggleBox };
